/* In your main CSS file (e.g., App.css) */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f3f4f6; /* Light background for contrast */
  }
  
  .loader {
    border: 8px solid #f3f4f6;
    border-top: 8px solid #3498db; /* Primary color */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  